@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&family=Dosis:wght@400&family=KoHo:wght@400&family=Nanum+Gothic&family=Do+Hyeon&family=Nanum+Pen+Script&display=swap");


* {
  box-sizing: border-box;
  user-select: none;
}

.App {
  /*text-align: center;*/
}

html {
  width: 100%;
  height: 100%;

  scroll-behavior: smooth;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

@keyframes Show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#logo {
  z-index: 999;

  position: fixed;
  padding: 15px;

  opacity: 0;
  width: 100%;
  height: 70px;
}

#logo a {
  text-decoration: none;
  color: #000000;
  font-family: "Dosis", "sans-serif";

  font-size: 25px;
  font-weight: bold;

  transition: 0.3s;
}

#logo.show {
  animation: Show 0.8s ease 1s forwards;
}

@media (min-width: 1101px) {
  #typing {
    font-size: 70px;
    padding-left: 200px;
    transform: translateY(500px);
  }
}

@media only screen and (max-width: 1100px) {
  #typing {
    font-size: 48px;
    padding-left: 150px;
    transform: translateY(500px);
  }
}

@media only screen and (max-width: 768px) {
  #typing {
    font-size: 25px;
    margin-left: -100px;
    transform: translateY(500px);
  }
}
